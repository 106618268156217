import React, { useEffect } from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"
import "../css/style.css"
import da from "../js/dynamic_adapt"
import { spollers } from "../js/functions"

const MasterPolicy = () => {
  useEffect(() => {
		da.init()
		spollers()
	}, [])
  return (
    <>
      <Seo title="Master Policy"/>
      <Layer>
        <main className="terms_and_conditions_main privacy_policy_main" style={{marginBottom: '100px'}}>
        	<div>
				<h2 class="title-pricing__title"><a href={'/masterpolicy.pdf'}> Link to Download Master policy</a></h2>
        	</div>
        </main>
      </Layer>
    </>
  )
}

export default MasterPolicy
